<template>
  <el-container>
    <el-header height="220">
      <el-row>
        <el-select
          v-model="queryInfo.subjectId"
          clearable
          placeholder="请选择科目"
          style="margin-left: 5px"
          @change="subjectChange"
        >
          <el-option
            v-for="item in allSubject"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <el-button type="plain" icon="el-icon-plus" style="margin-left: 5px" @click="addExamPaper">添加</el-button>
      </el-row>
    </el-header>

    <el-main>
      <el-table
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed="left"
          label="No"
          type="index"
        />
        <el-table-column
          prop="examName"
          label="试卷名称"
          width="150"
        />
        <el-table-column
          prop="type"
          label="所属科目"
          width="90"
        />
        <el-table-column
          prop="startTime"
          label="考试时间"
          width="150"
        />
        <el-table-column
          prop="duration"
          label="考试时长(分钟)"
          width="150"
        />
        <el-table-column
          prop="totalScore"
          label="考试总分"
        />
        <el-table-column
          prop="passScore"
          label="及格分数"
        />
        <el-table-column
          prop="passScore"
          label="试卷状态"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="320"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              @click="previewPaper(scope.$index, scope.row)"
            >预览</el-button>
            <el-button
              size="mini"
              type="warning"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :small="screenWidth <= 768"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>
  </el-container>
</template>

<script>
import {getSubjectKV, getPapers, deletePaper} from '@/api/exam'
import {deleteArticle} from "@/api/article";

export default {
  name: 'ExamPaper',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      queryInfo: {
        subjectId: null,
        pageNumber: 1,
        pageSize: 10
      },
      allSubject: []
    }
  },
  created() {
    document.title = '试卷管理'
    this.getData(this.queryInfo)
    this.getSubjects()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getData(this.queryInfo)
      // 回到顶部
      scrollTo(0, 0)
    },
    getData(queryInfo) {
      getPapers(queryInfo).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    getSubjects() {
      getSubjectKV().then((resp) => {
        if (resp.code === 0) {
          this.allSubject = resp.data
        }
      })
    },
    // 题库变化
    subjectChange(val) {
      this.queryInfo.subjectId = val
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getData(this.queryInfo)
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除 ' + row.examName + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePaper(row.examId).then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: '提示',
              message: '稿件已删除',
              type: 'warning',
              duration: 3000
            })
            this.$router.go(0)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    addExamPaper() {
      this.$router.push('/exam/paper/add')
    },
    previewPaper(index, row) {
      const paperId = row.examId
      const routeData = this.$router.resolve({ path: '/exam/preview/' + paperId })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style>
</style>
